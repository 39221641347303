import { call, put, takeEvery, take, fork, cancel } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { actions } from "./index";
import DataLayerHelper from "../../../utils/dataLayer";
import { REACT_APP_COP_SERVER } from "../../../utils/env";

// Helper to create an API request
const apiRequest = (url, method, body = null) => {
  return fetch(url, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: body ? JSON.stringify(body) : null,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  });
};

// Function to create an event channel for file upload progress
function createUploadChannel(formData) {
  return eventChannel((emit) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${REACT_APP_COP_SERVER}/api/v1/artworks.json`);

    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        emit({ progress });
      }
    });

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        emit({ success: JSON.parse(xhr.response) });
      } else {
        emit({ error: new Error(`Upload failed with status ${xhr.status}`) });
      }
      emit({ done: true });
    };

    xhr.onerror = () => {
      emit({ error: new Error("Network error") });
      emit({ done: true });
    };

    xhr.send(formData);

    return () => {
      xhr.abort(); // Cancel upload on saga cancellation
    };
  });
}

export function* upload(action) {
  const { files, options } = action.payload;
  const file = files[0];
  const requestStart = new Date();

  const formData = new FormData();
  formData.append("artwork[design]", file);

  const uploadChannel = yield call(createUploadChannel, formData);

  try {
    while (true) {
      const { progress, success, error, done } = yield take(uploadChannel);

      if (progress !== undefined) {
        console.log("[LOG PROGRESS]", progress);
        yield put(actions.uploadProgress(progress));
      }

      if (success) {
        yield put(
          options?.silentUpload
            ? actions.uploadSuccessSilent(success)
            : actions.uploadSuccess(success)
        );

        DataLayerHelper.addEventWithOrigin(
          "success",
          "file editor",
          options?.silentUpload ? "vector upload" : "upload",
          "duration",
          new Date() - requestStart
        );
      }

      if (error) {
        yield put(actions.uploadFail(error));
        DataLayerHelper.addEventWithOrigin(
          "error",
          "file editor",
          options?.silentUpload ? "vector upload" : "upload",
          `${error.message} - ${file.name}`
        );
      }

      if (done) {
        break;
      }
    }
  } finally {
    uploadChannel.close(); // Close the channel when finished
  }
}

// Show Project Saga
function* showArtwork(action) {
  try {
    const payload = yield call(
      apiRequest,
      `${REACT_APP_COP_SERVER}/api/v1/artworks/${action.payload}.json`,
      "GET"
    );
    yield put(actions.showArtworkSuccess(payload));
  } catch (error) {
    yield put(actions.showArtworkFail(error.message));
  }
}

// Watcher Saga
function* artworkSaga() {
  yield takeEvery(actions.showArtwork, showArtwork);
  yield takeEvery(actions.upload, upload);
}

export default artworkSaga;
