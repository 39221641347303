/**
 * The TeaserBar component
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";

/**
 * TeaserBar stateless component
 * - Notifies user that the page is offline
 * - Turn app offline to see the component
 */
const TeaserBar = (props, context) => {
  const [modalOpen, setModalOpen] = useState(false);
  const backgroundColor = "#002855";
  const highlightColor = "#FFD700";
  const textColor = "#FFFFFF";
  return (
    <div id="teaser">
      {/* <div
        className="p-2 text-center"
        style={{
          height: "auto",
          padding: "1em",
          background: "#169dce",
          color: "#fff",
          "font-size": "0.8em",
        }}
      >
        <strong>Achtung:</strong> Bitte beachte bei deiner Bestellung, dass die
        Lieferzeiten aufgrund der Feiertage und Betriebsurlaub (24.12.22 -
        1.1.23) etwas länger sind.
      </div> */}
      <div
        className="p-2 text-center"
        style={{
          height: "auto",
          padding: "1em",
          background: backgroundColor,
          color: textColor,
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "1.3em",
            marginBottom: "-0.75em",
          }}
        >
          <span style={{ color: highlightColor }}>
            🚀 🎁 <strong>Jahresschlussverkauf 2024!</strong> 🎁 🚀
          </span>{" "}
          -{" "}
          <span style={{ textDecoration: "underline" }}>
            Spare jetzt bis zu{" "}
            <span style={{ color: highlightColor }}>500€</span>
          </span>
          *{" "}
        </span>
        <Button size="sm" onClick={() => setModalOpen(true)} color="info">
          Deals anzeigen
        </Button>
        <br />
        <Modal
          style={{ backgroundColor: backgroundColor }}
          toggle={() => setModalOpen(false)}
          isOpen={modalOpen}
        >
          <ModalHeader
            style={{
              background: highlightColor,
              color: textColor,
            }}
            toggle={() => setModalOpen(false)}
          >
            🚀 🎁 <strong>Jahresschlussverkauf 2024!</strong> 🎁 🚀
          </ModalHeader>
          <ModalBody
            style={{ backgroundColor: backgroundColor, color: textColor }}
            className="text-white"
          >
            <p className="h5">Nur bis 31. Dezember 2024 Rabatt sichern:</p>
            <Table>
              <thead style={{ color: textColor }}>
                <tr>
                  <th>Rabatt</th>
                  <th>Gutschein Code</th>
                </tr>
              </thead>
              <tbody style={{ color: textColor }}>
                <tr>
                  <td>
                    <span className="h4">5€ Rabatt</span>
                    <br />
                    Ab 50€ Warenwert
                  </td>
                  <td className="h4">GOODBYE24</td>
                </tr>
                <tr>
                  <td>
                    <span className="h4">10€ Rabatt</span>
                    <br />
                    Ab 100€ Warenwert
                  </td>
                  <td className="h4">GOODBYE24-10</td>
                </tr>
                <tr>
                  <td>
                    <span className="h4">25€ Rabatt</span>
                    <br />
                    Ab 225€ Warenwert
                  </td>
                  <td className="h4">GOODBYE24-25</td>
                </tr>
                <tr>
                  <td>
                    <span className="h4">50€ Rabatt</span>
                    <br />
                    Ab 400€ Warenwert
                  </td>
                  <td className="h4">GOODBYE24-50</td>
                </tr>
                <tr>
                  <td>
                    <span className="h4">250€ Rabatt</span>
                    <br />
                    Ab 2.000€ Warenwert
                  </td>
                  <td className="h4">GOODBYE24-250</td>
                </tr>
                <tr>
                  <td>
                    <span className="h4">500€ Rabatt</span>
                    <br />
                    Ab 4.500€ Warenwert
                  </td>
                  <td className="h4">GOODBYE24-500</td>
                </tr>
              </tbody>
            </Table>
            <p className="small">
              Gutscheincode im Checkout einlösen. Eine nachträgliche
              Berücksichtigung, Barauszahlung oder Erstatung ist nicht möglich.
              Sollten sich durch eien Teilstornierung ein geringerer Warenkorb
              ergeben wird der Rabatt ggf. angepasst.
            </p>
          </ModalBody>
        </Modal>
        <span className="small">
          * Je nach Mindestwarenwert, Details unter "Deals anzeigen", Gültig bis
          31.12.2024 23:59. Im Bezahlprozess einlösen!{" "}
          <a
            style={{ color: textColor, textDecoration: "underline" }}
            href="https://www.myfolie.com/de/faq/gibt-es-gutscheinbedingungen/"
            target="_blank"
          >
            Alle Gutschein-Bedingungen.
          </a>
        </span>
      </div>
    </div>
  );
};

/** define proptypes for the form */
TeaserBar.propTypes = {};

/**  define proptype for the 'translation' function  */
TeaserBar.contextTypes = {
  t: PropTypes.func,
};
export default TeaserBar;
