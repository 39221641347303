import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  byIdDictionary: {}, // Stores artworks by ID
  queryDictionary: {}, // Stores queries and their results
  list: [],
  listLoading: false,
  listError: false,
  listFilters: {},
  show: null,
  showLoading: false,
  showError: false,
  uploadError: null,
  uploadLoading: false,
  uploadedFile: null,
  searchResults: [],
  currentSearchTerm: null,
  uploadProgress: 0,
  cropping: { active: false, crop: {} },
};

const addArtworkToDictionaries = (artwork, state) => {
  console.log("addArtworkToDictionaries", artwork);
  const { id } = artwork.data;
  state.byIdDictionary[id] = { id, type: "artwork", data: artwork.data };
};

const addArtworksToDictionaries = (artworks, state) => {
  artworks.forEach((artwork) => addArtworkToDictionaries(artwork, state));
};

const artworkSlice = createSlice({
  name: "artwork",
  initialState,
  reducers: {
    upload: (state) => {
      state.uploadLoading = true;
      state.uploadError = null;
      state.uploadProgress = 0;
    },
    uploadSuccess: (state, action) => {
      const artwork = action.payload;
      addArtworkToDictionaries(artwork, state);
      state.show = artwork;
      state.currentArtworkId = artwork.data.id;
      state.uploadLoading = false;
      state.uploadedFile = artwork;
      state.uploadProgress = 100;
    },
    uploadFail: (state, action) => {
      state.uploadLoading = false;
      state.uploadError = action.payload?.message || true;
      state.uploadProgress = 0;
    },

    listArtworks: (state, action) => {
      const { filters } = action.payload;
      state.listLoading = true;
      state.listFilters = filters;
      state.listError = null;
    },
    listArtworksSuccess: (state, action) => {
      const { data } = action.payload;
      addArtworksToDictionaries(data, state);
      state.list = data;
      state.listLoading = false;
    },
    listArtworksFail: (state, action) => {
      state.listLoading = false;
      state.listError = action.payload?.message || true;
    },

    searchArtworks: (state, action) => {
      state.listLoading = true;
      state.currentSearchTerm = action.payload.term;
    },
    searchArtworksSuccess: (state, action) => {
      const { data, term } = action.payload;
      addArtworksToDictionaries(data, state);
      state.searchResults = data;
      state.currentSearchTerm = term;
      state.listLoading = false;
    },
    searchArtworksFail: (state, action) => {
      state.listLoading = false;
      state.listError = action.payload?.message || true;
    },

    showArtwork: (state, action) => {
      const { id } = action.payload;
      state.show = state.byIdDictionary[id] || null;
      state.showLoading = true;
    },
    showArtworkSuccess: (state, action) => {
      const artwork = action.payload;
      addArtworkToDictionaries(artwork, state);
      state.show = artwork;
      state.showLoading = false;
    },
    showArtworkFail: (state, action) => {
      state.showLoading = false;
      state.showError = action.payload?.message || true;
    },
    // TODO LEGACY
    updateCrop: (state, action) => {
      state.cropping.crop = action.payload.crop;
    },
    startCrop: (state) => {
      state.cropping.active = true;
    },
    endCrop: (state) => {
      state.cropping = {
        active: false,
        crop: {},
      };
    },
    uploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
    reset: (state) => {
      state.uploading = false;
      state.uploaded = false;
      state.uploadedFile = null;
      state.uploadedSilent = false;
      state.preview = "";
      state.crop = false;
      state.currentArtworkId = null;
      state.cropping = { active: false, crop: {} };
      state.imagename = null;
      state.filetype = null;
      state.uploadError = null;
      state.uploadProgress = 0;
    },
  },
});

// Export actions and reducer
export const {
  upload,
  uploadSuccess,
  uploadFail,
  listArtworks,
  listArtworksSuccess,
  listArtworksFail,
  searchArtworks,
  searchArtworksSuccess,
  searchArtworksFail,
  showArtwork,
  showArtworkSuccess,
  showArtworkFail,
  updateCrop,
  startCrop,
  endCrop,
  reset,
  uploadProgress,
} = artworkSlice.actions;

export const actions = artworkSlice.actions;
export default artworkSlice.reducer;
