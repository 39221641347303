/**
 * Normalize ENVs
 */
export const {
  REACT_APP_API_SERVER,
  REACT_APP_ADYEN_ORIGIN_KEY,
  REACT_APP_ADYEN_ENVIRONMENT,
  REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_CDN_SERVER,
  REACT_APP_COP_SERVER,
  REACT_APP_SHOP_API_SERVER,
  REACT_APP_REVIEW_API_SERVER,
  REACT_APP_SHOP_CDN_SERVER,
  REACT_APP_SUPPORT_CENTER_API_SERVER,
  REACT_APP_WEB_SERVER,
  REACT_APP_FB_ID,
  REACT_APP_PAYPAL_CART_LIMIT,
  REACT_APP_GTM,
  REACT_APP_AMAZON_STORE_ID,
  REACT_APP_AMAZON_PUBLIC_KEY_ID,
  REACT_APP_AMAZON_MERCHANT_ID,
  REACT_APP_CONSENT_API_SERVER,
} = typeof window !== "undefined" ? window.env : process.env;
